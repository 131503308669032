<template>
  <base-page
    :title="$t('secretKeyRecord.title')"
    :formData="formData"
    :config="config"
    @search="search"
  >
    <div
      v-if="defaultContent.length"
      v-html="defaultContent.join('、')"
      style="padding-bottom: 10px"
    />
    <table-list
      ref="tableList"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.secretKeyFindRecordList"
      :columns="columns"
      :highlightCurrentRow="false"
      row-name="createTime"
      v-model="tableRow"
    >
      <!-- 使用时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
      <!-- 资源类型 -->
      <template v-slot:type="{ row }">
        {{ resourceTypeEnumObj[row.type] }}
      </template>
    </table-list>
  </base-page>
</template>

<script>
export default {
  data() {
    let _that = this
    let resourceTypeEnum = this.$dict.resourceTypeEnum()
    const { company, productConfigEntities} = this.$store.getters
    // const { level, feeModeStr, feeMode } = company
    console.log(productConfigEntities)
    const { level } = company
    let feeModeStrCh = []
    let feeModeEn = []
    productConfigEntities.forEach(item =>{
      feeModeStrCh.push(item.feeModeStr)
      feeModeEn.push(item.feeMode)
    })
    let feeModeStr = feeModeStrCh.join(",")
    let feeMode = feeModeEn.join(",")

    let showFeeMode = ''
    if (level === 'ONE' || level === 'TWO') {
      if (level === 'ONE') {
        showFeeMode = 'METERING,ACCOUNT_SUBSCRIPTIONS'
      } else {
        showFeeMode = feeModeStr
      }
    } else {
      showFeeMode = feeMode
    }

    resourceTypeEnum = resourceTypeEnum.filter((item) => {
      if (
        showFeeMode.indexOf('METERING') >= 0 &&
        showFeeMode.indexOf('ACCOUNT_SUBSCRIPTIONS') >= 0
      ) {
        return true
      } else if (showFeeMode.indexOf('METERING') >= 0) {
        if (item.value !== 'ACCOUNT_SUBSCRIPTIONS_NUMBER') return true
      } else {
        if (item.value !== 'VIDEO_CALL_DURATION') return true
      }
    })
    return {
      // 搜索
      formData: {},
      config: [
        {
          // tag: 'el-select',
          label: this.$t('secretKeyRecord.assignAccount'),
          prop: 'searchStr',
          span: 6,
        },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 8,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ],
      // 表格页
      tableRow: null,
      resourceTypeEnumObj: this.$util.listToObj(resourceTypeEnum),
      columns: [
        // lang:使用时间
        {
          label: this.$t('secretKeyRecord.createTime'),
          // prop: 'createTime',
          callback(row) {
            return _that.$util.format(row.serviceResourceRecord.createTime, 'yyyy-MM-dd')
          },
        },
        // lang:资源类型
        {
          label: this.$t('secretKeyRecord.userAccount'),
          prop: 'userAccount',
          callback(row) {
            return row.secretKeyDistribution.userAccount.name
          },
        },
        // lang:密钥类型
        {
          label: this.$t('secretKeyRecord.secretKeyType'),
          callback(row) {
            if (
              row.secretKeyDistribution.type === 'PRODUCT_KEY'
            ) {
              return _that.$t('businessEnum.PRODUCT_KEY')
            } else {
              return _that.$t('businessEnum.FUNCTION_KEY')
            }
          },
        },
        // lang:分配内容
        {
          label: this.$t('secretKeyRecord.content'),
          callback(row) {
            if(row.secretKeyDistribution.type === 'PRODUCT_KEY') {
              if(_that.$util.getLocalStorage('apiprefix') === 'tmva') {
                return _that.$t('keyDistribution.remoteGuidance')
              } else if(_that.$util.getLocalStorage('apiprefix') === 'workFlow') {
                return _that.$t('keyDistribution.workflow')
              }
            } else {
              return _that.$t(`businessEnum.${row.secretKeyDistribution.type}`)
            }
          },
        },
        // lang:密钥激活码
        {
          label: this.$t('secretKeyRecord.code'),
          callback(row) {
            return row.secretKeyDistribution.code
          },
        },
        // lang: 有效期
        {
          label: this.$t('secretKeyRecord.expirationDate'),
          callback(row) {
            return _that.$util.format(row.secretKeyDistribution.expirationDate, 'yyyy-MM-dd')
          },
        },
        // lang: 使用状态
        {
          label: this.$t('secretKeyRecord.status'),
          callback(row) {
            return _that.$t('businessEnum.DEDUCTED')
          },
        },
        // lang: 剩余数量
        {
          label: this.$t('secretKeyRecord.surplusNumber'),
          callback(row) {
            return row.serviceResourceRecord.surplusNumber
          },
        },
      ],
      // 资源明细
      defaultContent: [],
    }
  },
  mounted() {
    this.serviceResourceFindByCompany()
  },
  methods: {
    // 查询按钮点击回调
    search(isActiveQuery) {
      this.$refs.tableList.searchList(this.formData, isActiveQuery)
    },
    // 查询企业资源
    serviceResourceFindByCompany() {
      const { company, productConfigEntities } = this.$store.getters
      const { uid, level } = company
      const {feeMode, feeModeStr} = productConfigEntities.filter(item => item.type === 'TMVA')[0]
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.serviceResourceFindByCompany,
        data: {
          companyUid: uid,
        },
      }).then((data) => {
        console.log(data)
        if (!data) return
        let showFeeMode = ''
        if (level === 'ONE' || level === 'TWO') {
          if (level === 'ONE') {
            showFeeMode = 'METERING,ACCOUNT_SUBSCRIPTIONS'
          } else {
            showFeeMode = feeModeStr
          }
        } else {
          showFeeMode = feeMode
        }
        const {
          remainAccountNumber,
          remainVideoCallDuration,
          remainAccountSubscriptionsNumber,
          remainEquipmentNumber,
          remainEquipmentComponentsNumber,
          remainArDigitalDisplayNumber,
          remainCloudRecordingStorageSpace,
          cloudRecordingStorageSpace,
          remainOssStorageSpace,
          ossStorageSpace,
          productKeyNumber,
          remainProductKeyNumber,
          thermalImageryFunctionKeyNumber,
          remainThermalImageryFunctionKeyNumber,
          teamViewerFunctionKeyNumber,
          remainTeamViewerFunctionKeyNumber,
          sensorCollectFunctionKeyNumber,
          remainSensorCollectFunctionKeyNumber,
          endoscopeFunctionKeyNumber,
          remainEndoscopeFunctionKeyNumber
        } = data
        let defaultContent = this.$dict.resourceTypeEnum(this.$util.getLocalStorage('apiprefix')).map((item) => {

          let remainNumber = 0
          switch (item.value) {
            case 'ACCOUNT_NUMBER': // 账号数量(个)
              remainNumber = remainAccountNumber
              break
            case 'VIDEO_CALL_DURATION': // 通话时长(分钟)
              remainNumber = +parseFloat(remainVideoCallDuration / 60).toFixed(
                2
              )
              break
            case 'ACCOUNT_SUBSCRIPTIONS_NUMBER': // 账号订阅数量(个)
              remainNumber = remainAccountSubscriptionsNumber
              break
            case 'EQUIPMENT_NUMBER': // 设备码数量(个)
              remainNumber = remainEquipmentNumber
              break
            case 'EQUIPMENT_COMPONENTS_NUMBER': // 设备备件数量(个)
              remainNumber = remainEquipmentComponentsNumber
              break
            case 'AR_DIGITAL_DISPLAY_NUMBER': // AR数显数量(个)
              remainNumber = remainArDigitalDisplayNumber
              break
            case 'CLOUD_RECORDING_STORAGE_SPACE': // 云录制空间(G)
              remainNumber = +parseFloat(
                cloudRecordingStorageSpace / 1024 / 1024 / 1024
              ).toFixed(4)
              break
            case 'REMAIN_CLOUD_RECORDING_STORAGE_SPACE': // 云录制空间(G)
              remainNumber = +parseFloat(
                  remainCloudRecordingStorageSpace / 1024 / 1024 / 1024
              ).toFixed(4)
              break
            case 'OSS_STORAGE_SPACE': // 云存储空间(G)
              remainNumber = +parseFloat(
                ossStorageSpace / 1024 / 1024 / 1024
              ).toFixed(4)
              break
            case 'REMAIN_OSS_STORAGE_SPACE': // 云存储空间(G)
              remainNumber = +parseFloat(
                  remainOssStorageSpace / 1024 / 1024 / 1024
              ).toFixed(4)
              break
            case 'PRODUCT_KEY_NUMBER': // 产品密钥数量
              remainNumber = productKeyNumber
              break
            case 'REMAIN_PRODUCT_KEY_NUMBER': // 剩余产品密钥数量
              remainNumber = remainProductKeyNumber
              break
            case 'THERMAL_IMAGERY_FUNCTION_KEY_NUMBER': // 热成像功能密钥数量
              remainNumber = thermalImageryFunctionKeyNumber
              break
            case 'REMAIN_THERMAL_IMAGERY_FUNCTION_KEY_NUMBER': // 剩余热成像功能密钥数量
              remainNumber = remainThermalImageryFunctionKeyNumber
              break
            case 'TEAM_VIEWER_FUNCTION_KEY_NUMBER': // 远程控制功能密钥数量
              remainNumber = teamViewerFunctionKeyNumber
              break
            case 'REMAIN_TEAM_VIEWER_FUNCTION_KEY_NUMBER': // 剩余远程控制功能密钥数量
              remainNumber = remainTeamViewerFunctionKeyNumber
              break
            case 'SENSOR_COLLECT_FUNCTION_KEY_NUMBER': // 传感器采集功能密钥数量
              remainNumber = sensorCollectFunctionKeyNumber
              break
            case 'REMAIN_SENSOR_COLLECT_FUNCTION_KEY_NUMBER': // 剩余传感器采集成像功能密钥数量
              remainNumber = remainSensorCollectFunctionKeyNumber
              break
            case 'ENDOSCOPE_FUNCTION_KEY_NUMBER': // 内窥镜功能密钥数量
              remainNumber = endoscopeFunctionKeyNumber
              break
            case 'REMAIN_ENDOSCOPE_FUNCTION_KEY_NUMBER': // 剩余内窥镜功能密钥数量
              remainNumber = remainEndoscopeFunctionKeyNumber
              break
            default: // do something
          }
          return {
            assignName: `${
              item.label
            }: <span style="color: #0d69b9; font-weight: bold"> ${
              remainNumber || 0
            } </span>`,
            remainNumber: remainNumber,
            type: item.value,
          }
        })
        defaultContent = defaultContent.filter((item) => {
          console.log('showFeeMode', showFeeMode)
          if (
            showFeeMode.indexOf('METERING') >= 0 &&
            showFeeMode.indexOf('ACCOUNT_SUBSCRIPTIONS') >= 0
          ) {
            return true
          } else if (showFeeMode.indexOf('METERING') >= 0) {
            if (item.type !== 'ACCOUNT_SUBSCRIPTIONS_NUMBER') return true
          } else {
            if (item.type !== 'VIDEO_CALL_DURATION') return true
          }
        })
        this.defaultContent = defaultContent.map((item) => {
          return item.assignName
        })
      })
    },
  },
}
</script>
